import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  font-family: 'Vazir', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 30px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Vazir', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin-bottom: 20px;
    font-family: 'Vazir', sans-serif;
  border: 2px solid #fff;
  border-radius: 10px;
  font-size: 1.2rem;
  text-align: center;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
    font-family: 'Vazir', sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
  
  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

const ErrorMessage = styled.p`
  color: #ff4040;
  font-size: 1rem;
  margin-top: 15px;
  background: rgba(255, 64, 64, 0.1);
  padding: 10px;
  border-radius: 5px;
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  margin: 20px 0;
    font-family: 'Vazir', sans-serif;
  text-align: center;
`;

const TelegramButton = styled(Button)`
  background-color: #0088cc;
  margin-top: 15px;
    font-family: 'Vazir', sans-serif;

  &:hover {
    background-color: #005f99;
  }
`;

const LicenseInputPage = ({ onLicenseSubmit }) => {
  const [licenseKey, setLicenseKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmiter = async () => {
    try {
      const response = await fetch('https://s1.nitropardazesh.site/api/license/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ license_key: licenseKey }),
      });

      if (!response.ok) {
        setErrorMessage('خطای سرور. لطفاً دوباره تلاش کنید.');
        return;
      }

      const result = await response.json();

      if (result.message === 'License status Activated') {
        localStorage.setItem('license', licenseKey);
        onLicenseSubmit();
      } else {
        setErrorMessage('کد فعال سازی نامعتبر است. لطفاً دوباره تلاش کنید.');
      }
    } catch (error) {
      setErrorMessage('خطایی رخ داده است. لطفاً دوباره تلاش کنید.');
      console.error('Error:', error);
    }
  };

  const handleTelegramClick = () => {
    window.open('https://t.me/igame_mod_bot', '_blank');
  };

  return (
    <Container>
      <Title>وارد کردن کد فعال سازی</Title>
      <Input
        type="text"
        value={licenseKey}
        onChange={(e) => setLicenseKey(e.target.value)}
        placeholder="کد فعال سازی خود را وارد کنید"
      />
      <Button onClick={handleSubmiter}>ارسال</Button>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <InfoText>برای استفاده از برنامه نیاز به خرید کد فعال سازی دارید</InfoText>
      <TelegramButton onClick={handleTelegramClick}>خرید کد فعال سازی</TelegramButton>
    </Container>
  );
};

export default LicenseInputPage;